import React, {useState} from 'react'
import * as styles from './styles.module.css'
import { Link } from "gatsby"
import { useSelector, useDispatch } from 'react-redux'
import { login } from '../../reducers/actions/user.js'
import Loading from '../../assets/icons/loading.svg'
import { pipe, cond, T, tap } from 'ramda'
import { isValidEmail, isValidPassword } from '../../utils/common.js'
import LoginImage from '../../images/login.png'

const Login = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const loginState = useSelector(state => (state.userReducer.login))
  
  const getTargetValue = fn => event => fn(event.target.value)
  const dispatch = useDispatch()

  const loginRequest = () =>  pipe(
    tap(() => setError('')),
    cond([
      [({email}) => !isValidEmail(email), () => setError('Email no válido')],
      [({password}) => !isValidPassword(password), () => setError('La contraseña debe ser mínimo de 8 caracteres')],
      [T, login(dispatch)]
    ])
  )({email, password})

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      loginRequest()
    }
  }

  const getError = () => {
    if(error.length > 0) {
      return error
    }
    if(loginState.errorMsg.length > 0) {
      return loginState.errorMsg
    }
    return ''
  }

  return (
    <div className={styles.container}>
      <div className={styles.box}>
        <div>
          <img height={450} width={450} src={LoginImage}/>
        </div>
        <div className={styles.form}>
          <h1>Bienvenido</h1>
          <input value={email} placeholder={'Email'} onChange={getTargetValue(setEmail)} />
          <input value={password} placeholder={'Contraseña'} type='password' onChange={getTargetValue(setPassword)} onKeyDown={handleKeyDown} />
          <button
            className={'primary'}
            style={{width: '100%'}}
            onClick={loginRequest}
            disabled={!!loginState.loading}
          >
            <div className={styles.btnContent}>
              <span>ingresar</span>
              { !!loginState.loading && <Loading /> }
            </div>
          </button>
          <p>{getError()}</p>
          <div className={styles.accountOptions}>
            <Link to={'/forgot-password/'}>Olvide mi contraseña</Link>
            <Link to={'/register/'}>Registrarme</Link>
          </div>
       </div>
      </div>
    </div>
  )
}

export default Login
