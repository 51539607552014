import React from "react"
import Layout from "../components/layoutLogin"
import Login from "../components/login"
import SEO from "../components/seo"
import { useSelector } from 'react-redux'
import { navigate } from '@reach/router'
import withLocation from '../HOC/withLocation.js'

const LoginPage = ({search}) => {  
  const loginState = useSelector(state => (state.userReducer.login))
  if(loginState.response) {
    navigate(`/${search.to ? search.to : ''}`)
  }
  return (
    <Layout>
      <SEO title="Login" />
      <Login/>
    </Layout>
  )
}

export default withLocation(LoginPage)
